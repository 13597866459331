import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { FILE_TYPES } from "../../../services/Constants";
import { LinearProgressWithLabel } from "../UploadItem.jsx";
import ClusterFileUploadComplete from "../ClusterFileUploadComplete.jsx";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CommentImageSelector({
  updateAttachmentsList,
  uploadedItems,
  currentFileBeingUploaded,
  uploadProgress,
  isUploading,
  allFilesUploadComplete,
  handleClose,
}) {
  const theme = useTheme();
  const filePickerInput = useRef();
  const [images, setImages] = useState(null);

  const onImageChange = (event, previouslySelectedFiles) => {
    const items = previouslySelectedFiles || event?.target?.files;
    console.log(items);
    let list = [];
    if (items?.length) {
      for (let i = 0; i < items?.length; i++) {
        const element = items[i];
        const objUrl = URL.createObjectURL(element);
        list.push({ url: objUrl, name: element?.name, file: items[i] });
      }
    }
    setImages(list);
    updateAttachmentsList?.(list);
  };

  const removeItemsFromSelection = (item) => {
    const newList = images.filter((i) => {
      return i?.name !== item?.name;
    });

    setImages(newList);
  };

  return (
    <>
      {allFilesUploadComplete ? (
        <ClusterFileUploadComplete
          handleClose={() => {
            setImages(null);
            handleClose();
          }}
        />
      ) : (
        <>
          {images?.length ? (
            <div style={{ marginTop: "1em" }}>
              <Typography variant="button">
                Attachments ({images?.length})
              </Typography>
              <div
                id="thumbnails-container"
                style={{
                  display: "block",
                  flexWrap: "wrap",
                  top: 0,
                  left: 0,
                  height: "500px",
                  // marginTop: "1em",
                  paddingTop: "1em",
                  overflow: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px dashed",
                  borderColor: grey[500],
                  background: grey[200],
                }}
              >
                {images?.length
                  ? images?.map((i) => (
                      <>
                        <div style={{ paddingLeft: "1em" }} key={i?.name}>
                          <Typography
                            variant="body1"
                            style={{ fontSize: "small" }}
                          >
                            {i?.name}
                          </Typography>
                          <div
                            style={{ position: "relative", display: "block" }}
                          >
                            <IconButton
                              title="Remove"
                              color="primary"
                              onClick={() => {
                                removeItemsFromSelection(i);
                              }}
                              style={{
                                position: "absolute",
                                right: "0px",
                                top: "-24px",
                                zIndex: "1",
                                // marginRight: "9px",
                                // marginTop: "24px",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                            <div
                              style={{ position: "relative", display: "block" }}
                            >
                              {isUploading ? (
                                <div
                                  className="cover-for-loader"
                                  style={{
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    background: "rgb(239,239,239, 0.5)",
                                  }}
                                >
                                  <>
                                    {currentFileBeingUploaded === i?.name ? (
                                      <CircularProgressWithLabel
                                        value={
                                          uploadProgress
                                            ? uploadProgress * 100
                                            : 0
                                        }
                                      />
                                    ) : (
                                      <>
                                        {uploadedItems?.indexOf(i?.name) >
                                        -1 ? (
                                          <ClusterFileUploadComplete
                                            widthOutContainer={true}
                                            height={50}
                                            width={50}
                                          />
                                        ) : (
                                          <CircularProgress color="primary" />
                                        )}
                                      </>
                                    )}
                                  </>
                                </div>
                              ) : null}

                              <img
                                className="thumbnail-img"
                                alt="preview"
                                src={i?.url}
                                style={{
                                  width: "468px",
                                  height: "auto",
                                  marginRight: "1em",
                                  marginBottom: "1em",
                                  border:
                                    "1px solid " + theme.palette.primary[700],
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  : null}
              </div>
            </div>
          ) : (
            <div id="image-select-input" style={{ marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  top: 0,
                  left: 0,
                  height: "74px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px dashed",
                  borderColor: grey[500],
                  background: grey[200],
                }}
              >
                <FormLabel
                  htmlFor="multi-file-upload"
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2em",
                    padding: 1,
                    minWidth: "200px",
                    width: "auto",
                    borderRadius: 2,
                    border: "1px solid white",
                    cursor: "pointer",
                    color: "#fff",
                    background: theme.palette.primary[700],
                    boxShadow: 10,
                  }}
                >
                  Attach Images
                </FormLabel>
              </div>
              <div style={{ display: "none" }}>
                <input
                  multiple
                  type="file"
                  id="multi-file-upload"
                  ref={filePickerInput}
                  onChange={onImageChange}
                  // accept={fileType?.accept || FILE_TYPES.ALL.accept}
                  accept={FILE_TYPES.IMAGE.accept}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
