import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Button,
  ListItemText,
  Popper,
  Paper,
  ButtonBase,
  Typography,
  CircularProgress,
  useTheme,
  alpha,
} from "@mui/material";
import "./../../../styles/search.css";

function SearchSuggestionBox({
  tempSelect,
  suggestionRef,
  mode,
  data,
  options,
  selOptions,
  searchQuery = "",
  isMultiSelect = false,
  onValueChanged = (option) => {},
  showSuggestions,
  anchorEl,
  setData,
  customSuggestion = false,
  isLoading,
  setIsLoading,
  setQuery,
}) {
  const [selectedOptions, setSelectedOptions] = useState(selOptions);
  const suggestionStyle = useMemo(() => {
    return options.length + selOptions.length > 10
      ? { maxHeight: "300px", overflowY: "auto" }
      : { maxHeight: "auto", overflowY: "visible" };
  }, [options.length, selOptions.length]);
  const theme = useTheme();
  const tempSelectedOptions = useMemo(() => {
    if (
      (mode === "value" || mode === "editValue") &&
      tempSelect.current.length > 0
    ) {
      return tempSelect.current;
    }
    return selOptions;
  }, [selOptions, mode, tempSelect]);

  useEffect(() => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected !== tempSelectedOptions) {
        return tempSelectedOptions;
      }
      return prevSelected;
    });
  }, [tempSelectedOptions]);

  const handleMore = () => {
    setIsLoading(true);
    setData((prevValues) => ({
      ...prevValues,
      page: prevValues.page + 1,
    }));
  };
  const isOptionSelected = (option) => {
    return selectedOptions.filter((opt) => opt.id === option.id).length > 0;
  };

  const onOptionSelected = (option) => {
    let newOptions = [];

    if (isMultiSelect) {
      if (isOptionSelected(option)) {
        newOptions = selectedOptions.filter((i) => {
          if (i.id !== option.id) {
            return true;
          }
          return false;
        });
      } else {
        newOptions = [...selectedOptions, option];
      }
    } else {
      newOptions = [option];
    }
    if (mode === "value" || mode === "editValue")
      tempSelect.current = newOptions;
    setSelectedOptions(newOptions);
    handleOptionClick(newOptions);
  };

  const handleOptionClick = (newOptions) => {
    if (newOptions.length > 0 && !isMultiSelect && mode !== "editValue") {
      setQuery("");
      setSelectedOptions([]);
      tempSelect.current = [];
      onValueChanged(newOptions);
    }
  };
  return (
    <>
      {
        <Popper
          open={(showSuggestions && options.length > 0) || isLoading}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ zIndex: 10 }}
          ref={suggestionRef}
        >
          <Paper>
            {isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  padding: "10px",
                  minWidth: "100px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <ul
                  className={`searchResults ${
                    options.length > 0 ? "show" : "hidden"
                  }`}
                  style={suggestionStyle}
                >
                  {selectedOptions.length > 0 && (
                    <>
                      <Typography
                        variant="subtitle2"
                        style={{ marginBottom: "4px" }}
                      >
                        Selected options
                      </Typography>
                      {selectedOptions.map((option) => (
                        <li
                          style={{
                            height: "32px",
                            maxWidth: "250px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            backgroundColor: alpha(
                              theme.palette.primary.main,
                              0.1
                            ),
                            borderBlockColor: "white",
                          }}
                          key={option.id}
                          onClick={() => onOptionSelected(option)}
                          title={option.value}
                        >
                          <ListItemText primary={option.value} />
                        </li>
                      ))}
                      <hr style={{ width: "100%", margin: "8px 0" }} />
                    </>
                  )}
                  {options.map(
                    (option) =>
                      !isOptionSelected(option) && (
                        <li
                          style={{
                            height: "32px",
                            maxWidth: "250px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          key={option.id}
                          onClick={() => onOptionSelected(option)}
                          title={option.value}
                        >
                          <ListItemText primary={option.value} />
                        </li>
                      )
                  )}
                  {customSuggestion && data.rowCount !== options.length && (
                    <ButtonBase className="more-container" onClick={handleMore}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Show More
                      </Typography>
                    </ButtonBase>
                  )}
                </ul>
                {(mode === "editValue" || isMultiSelect) && (
                  <Button
                    variant="contained"
                    className="doneBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      setQuery("");
                      tempSelect.current = [];
                      onValueChanged(
                        options.length > 0
                          ? selectedOptions
                          : [{ id: searchQuery, value: searchQuery }]
                      );
                    }}
                  >
                    Done
                  </Button>
                )}
              </>
            )}
          </Paper>
        </Popper>
      }
    </>
  );
}
export default SearchSuggestionBox;
