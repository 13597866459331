import React from "react";
import { Box } from "@mui/material";
import Lottie from "react-lottie";
import successAnimation from "../../lottieAnimations/checkMarkSuccessData.json";

function ClusterFileUploadComplete({
  handleClose,
  height = 300,
  width = 300,
  widthOutContainer,
}) {
  return (
    <>
      {widthOutContainer ? (
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: successAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={height}
          width={width}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                setTimeout(() => {
                  handleClose?.();
                }, 2000);
              },
            },
          ]}
        />
      ) : (
        <Box
          id="container"
          sx={{
            width: "100%",
            height: 600,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: successAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={height}
              width={width}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () => {
                    setTimeout(() => {
                      handleClose?.();
                    }, 2000);
                  },
                },
              ]}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default ClusterFileUploadComplete;
