import React, { useState, useCallback, useRef } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  List,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Constants, {
  SIMULATION_PACKAGE_TYPE,
  SIMULATION_UPLOAD_STATUS,
  IMAGE_UPLOAD_ERRORS,
} from "../../services/Constants";
import { useToast } from "../toast";
import semver, { valid } from "semver";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createClusterSimulation,
  getClusterImageByName,
  updateClusterImageUploadStatus,
} from "../../services/cluster";
import { useTheme } from "@mui/material/styles";
import SourceIcon from "@mui/icons-material/Source";
import JavascriptIcon from "@mui/icons-material/Javascript";
import HtmlIcon from "@mui/icons-material/Html";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import uploadFileUsingS3Api from "../../services/AwsFileUploader.js";
import { getStsToken, getServerConfiguration } from "../../services/Image";
import { UploadItem } from "./UploadItem.jsx";

function ClusterSimulationFileUploadDialog({ handleClose, openModal }) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  let { UPLOAD_STATUS: UploadStatus } = Constants;
  const filePickerInput = useRef();
  const { addToast } = useToast();
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [version, setVersion] = useState("");
  const [type, setType] = useState(SIMULATION_PACKAGE_TYPE.CLUSTER);
  const [isVersionValid, setIsVersionValid] = useState(valid(version));
  const [previousVersion, setPreviousVersion] = useState("");
  const [versionHelperText, setVersionHelperText] = useState(
    "Version cannot be empty!"
  );
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [simulationsRepoBucket, setSimulationsRepoBucket] = useState("");
  const [uploadInstance, setUploadInstance] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadProgress, setUploadProgress] = useState("");
  const [isChangeLogError, setIsChangeLogError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [imageUploadActionLoading, setImageUploadActionLoading] =
    useState(false);

  const [currentFileBeingUploaded, setCurrentFileBeingUploaded] = useState("");
  // const [uploadedItems, setUploadedItems] = useState({}); // {"index.html": true, "index.js": true}
  const [uploadedItems, setUploadedItems] = useState([]);

  const [stsToken, setStsToken] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    sessionToken: "",
  });

  const iconsObj = {
    html: <HtmlIcon />,
    js: <JavascriptIcon />,
    data: <SourceIcon />,
    wasm: <MiscellaneousServicesIcon />,
  };

  useQuery("getServerConfig", getServerConfiguration, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: serverConfig = {} } = data;
      setSimulationsRepoBucket(serverConfig.s3.SIMULATION_UPLOAD_BUCKET);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  useQuery("stsToken", getStsToken, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: token = {} } = data;
      setStsToken(token);
    },
    onError: (err) => {
      setIsChangeLogError(true);
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const { mutate: updateUploadStatusToDb } = useMutation(
    updateClusterImageUploadStatus,
    {
      onSuccess: async ({ data }) => {},
      onError: (err) => {},
      onSettled: () => {
        queryClient.invalidateQueries("clusterimages");
      },
    }
  );

  const updateUploadStatus = (simulationUploadId, uploadStatus) => {
    const payload = {
      simulationUploadId: simulationUploadId,
      uploadStatus: uploadStatus,
    };
    updateUploadStatusToDb(payload);
  };

  const simulationUploadAction = async ({
    file,
    data,
    contentType,
    relativePath,
  }) => {
    const fileObject = relativePath?.length ? relativePath : file?.name;
    const payload = {
      bucket: simulationsRepoBucket,
      key: data.simulationUploadId + "/" + fileObject,
      selectedFile: file,
      onInit: (instance) => {
        setUploadInstance?.(instance || null);
      },
      onProgress: (progress) => {
        console.log("Progress data = ", progress);
        setUploadStatus(UploadStatus.InProgress);
        if (progress?.part === 2) {
          updateUploadStatus(data.simulationUploadId, UploadStatus.InProgress);
        }
        // setUploadProgress({
        //   ...progress,
        //   uploadPct: progress.loaded / progress.total,
        // });
        const percentage = progress.loaded / progress.total;
        // console.log("Percentage  = ", percentage);
        setUploadProgress(percentage);
      },
      creds: stsToken,
    };
    if (contentType) {
      payload.contentType = contentType;
    }
    try {
      // setImageUploadActionLoading(true);
      const result = await uploadFileUsingS3Api(payload);

      if (result) {
        setUploadStatus(UploadStatus.Completed);
        updateUploadStatus(data.simulationUploadId, UploadStatus.Completed);
      }
    } catch (error) {
      const { message = IMAGE_UPLOAD_ERRORS.SOMETHING_WENT_WRONG } = error;
      setErrorMsg(message);
      setUploadStatus(UploadStatus.Failed);
    } finally {
      setImageUploadActionLoading(false);
    }
  };

  const { data: previousSimulationData } = useQuery(
    ["checkIfSimulationExists", { name }],
    getClusterImageByName,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setVersion(data?.version);
        setDescription(data?.description);
        // setPreviousVersion(
        //   `Previous version of "${data?.simulationName}" is ${data?.version}`
        // );
        setVersionHelperText(
          `Previous version of "${data?.simulationName}" is ${data?.version}`
        );
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          if (errorData.statusCode !== 404) {
            addToast({
              type: "error",
              message: errorData.message,
              autoClose: 3000,
            });
          }
        }
      },
    }
  );

  const resetUploadForm = () => {
    setName("");
    setType(SIMULATION_PACKAGE_TYPE?.CLUSTER);
    setDescription("");
    setVersion("");
    setSelectedFilesList([]);

    queryClient.invalidateQueries("clusterimages");
    queryClient.invalidateQueries("getClusterImagesData");
  };

  const getRelativePathStringWithObjectName = (i) => {
    const path = i?.webkitRelativePath;
    const pathParts = path.split("/");
    pathParts.shift();
    const pathString = pathParts.join("/");
    console.log("processed path string with object name : ", pathString);
    return pathString;
  };

  const startUploadingFiles = async (data) => {
    // TODO
    console.log(selectedFilesList);
    const itemsUploaded = [];
    for (const file of selectedFilesList) {
      // start of uploading
      setUploadProgress(0);
      // setCurrentFileBeingUploaded(file?.name);
      setCurrentFileBeingUploaded(file?.webkitRelativePath);
      console.log(file);

      // start file upload
      const filePayload = {
        file,
        data,
      };
      const fileRelativePath = getRelativePathStringWithObjectName(file);
      if (!!fileRelativePath) {
        filePayload.relativePath = fileRelativePath;
      }
      if (file?.type === "text/html") {
        filePayload.contentType = "text/html";
      }
      const contents = await simulationUploadAction(filePayload);
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // After upload is done for this file
      // const uploadedFiles = JSON.parse(JSON.stringify(uploadedItems));
      // uploadedFiles[file?.name] = true;
      itemsUploaded.push(file?.name);
      // const newItems = [...uploadedItems, file?.name];
      console.log("New uploaded Items", itemsUploaded);
      setUploadedItems(itemsUploaded);
    }
    setCurrentFileBeingUploaded("");
    setIsUploading(false);
    resetUploadForm();
    handleClose();
  };

  const { mutate: createSimulation, isLoading: isCreatingEntry } = useMutation(
    createClusterSimulation,
    {
      onSuccess: async ({ data }) => {
        // setUploadStatus(UploadStatus.Started);
        // imageUploadAction(data);
        const { awsImagePutUrl } = data;
        console.log("upload url - " + awsImagePutUrl);
        // handleClose();
        // resetUploadForm();
        queryClient.invalidateQueries("clusterimages");
        queryClient.invalidateQueries("getClusterImagesData");
        startUploadingFiles?.(data);
      },
      onError: (err) => {
        //Api call error block
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          addToast({
            type: "error",
            message: errorData.message,
            autoClose: 3000,
          });
        }
        // setImageUploadActionLoading(false);
      },
      onSettled: () => {
        // setIsUploading(false);
        // setImageUploadActionLoading(true);
        // queryClient.invalidateQueries("images");
        // queryClient.invalidateQueries("getImagesData");
      },
    }
  );

  const validateVersion = useCallback(
    (ver = version) => {
      if (!!ver) {
        const isValid = valid(ver);
        const previousVersion = previousSimulationData?.version;
        const isSameAsLastVersion = ver === previousVersion ? true : false;
        let isLessThanPreviousVersion = false;
        if (!!previousVersion) {
          isLessThanPreviousVersion = semver.lt(ver, previousVersion);
        }
        setIsVersionValid(isValid && !isSameAsLastVersion);
        if (isValid) {
          if (!previousVersion) {
            setIsVersionValid(true);
            setVersionHelperText("");
          } else if (isSameAsLastVersion) {
            setIsVersionValid(false);
            setVersionHelperText("Version cannot be same as last version!");
          } else if (isLessThanPreviousVersion) {
            // TODO
            setIsVersionValid(false);
            setVersionHelperText(
              `Version cannot be less than the last version! i.e. (${previousVersion})`
            );
          } else {
            setIsVersionValid(true);
            setVersionHelperText("");
          }
        } else {
          setIsVersionValid(false);
          setVersionHelperText(Constants.INVALID_VERSION_HELPER_TEXT);
        }
      } else {
        setIsVersionValid(false);
        // setVersionHelperText("Version cannot be empty!");
      }
    },
    [previousSimulationData?.version, version]
  );

  const addSimulation = () => {
    setIsUploading(true);
    const payload = {
      simulationName: name,
      simulationType: type,
      version,
      description,
      uploadStatus: SIMULATION_UPLOAD_STATUS.Started,
    };

    createSimulation?.(payload);
  };

  const versionInputHandler = (event) => {
    const {
      target: { value },
    } = event;
    setVersion(value);
    // validateVersion(value); // TODO
  };

  const handleTypeChange = (e) => {
    console.log(e?.target?.value);
    setType(e?.target?.value);
  };

  const onHandleClose = () => {
    resetUploadForm?.();
    handleClose?.();
  };

  return (
    <>
      <Dialog
        open={openModal}
        maxWidth="sm"
        // fullWidth
        scroll="paper"
        PaperProps={{
          style: {
            maxHeight: "90%",
          },
        }}
      >
        <DialogTitle>
          Upload Simulation
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose?.();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <>
            {" "}
            <Typography
              color="text.primary"
              sx={{ mt: 1 }}
              style={{ marginBottom: "10px" }}
            >
              Input Simulation details
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {" "}
                <Stack spacing={2}>
                  <>
                    <TextField
                      disabled={isUploading}
                      size="small"
                      hasError={false}
                      inputMode="text"
                      isRequired={true}
                      label="Name"
                      placeholder=""
                      type="text"
                      wrap="nowrap"
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        setName(val);
                      }}
                      value={name}
                    />
                    <TextField
                      disabled={isUploading}
                      size="small"
                      label={"Description"}
                      type="text"
                      isRequired={true}
                      onChange={(e) => {
                        const val = e.currentTarget.value;
                        setDescription(val);
                      }}
                      value={description}
                    />
                    <Grid container>
                      <Grid
                        item
                        sx={{
                          marginRight: "1em",
                          marginBottom: "1em",
                          width: "200px",
                        }}
                      >
                        <TextField
                          // variant="standard"
                          disabled={isUploading}
                          size="small"
                          label={"Version"}
                          type="text"
                          onChange={versionInputHandler}
                          value={version}
                          // helperText={
                          //   versionHelperText ? versionHelperText : ""
                          // }
                          // value={
                          //   packageType === DEPLOYMENT_PACKAGE_TYPE.SMARTCORE
                          //     ? manifestData?.version
                          //     : partiallyUploadedImageData?.version
                          // }
                          style={{ width: "100%" }}
                          // error={!isVersionValid}
                          // helperText={
                          //   !!resumeUploadId
                          //     ? ""
                          //     : !isVersionValid
                          //     ? versionHelperText
                          //     : ""
                          // }
                          // disabled={
                          //   (packageType?.length &&
                          //     packageType ===
                          //       DEPLOYMENT_PACKAGE_TYPE.SMARTCORE) ||
                          //   !!resumeUploadId
                          // }
                        />
                      </Grid>
                      <Grid item sx={{ width: "200px" }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            Simulation Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Simulation type"
                            onChange={handleTypeChange}
                            disabled={isUploading}
                          >
                            <MenuItem value={SIMULATION_PACKAGE_TYPE.OTHERS}>
                              Others
                            </MenuItem>
                            <MenuItem value={SIMULATION_PACKAGE_TYPE.CLUSTER}>
                              Cluster
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>

                  <div id="folder-select-area">
                    {selectedFilesList?.length > 0 ? (
                      <>
                        <div id="folder-select-info">
                          <Box
                            sx={{
                              top: 0,
                              left: 0,
                              minHeight: "300px",
                              height: "auto",
                              width: "100%",
                              border: "2px dashed",
                              borderColor: grey[500],
                              padding: "1em",

                              maxHeight: "456px",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            <List
                              dense
                              subheader={
                                <div>
                                  <Typography variant="button">
                                    {`Selected Files (${selectedFilesList?.length})`}
                                  </Typography>
                                  <Button
                                    disabled={isUploading}
                                    startIcon={<PlaylistRemoveIcon />}
                                    style={{
                                      position: "absolute",
                                      right: "-21px",
                                    }}
                                    onClick={() => {
                                      setSelectedFilesList([]);
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </div>
                              }
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                              }}
                            >
                              {selectedFilesList?.map((file) => (
                                <UploadItem
                                  file={file}
                                  isUploading={isUploading}
                                  uploadProgress={
                                    currentFileBeingUploaded ===
                                    file?.webkitRelativePath
                                      ? uploadProgress
                                      : null
                                  }
                                  currentFileBeingUploaded={
                                    currentFileBeingUploaded ===
                                    file?.webkitRelativePath
                                      ? true
                                      : false
                                  }
                                  uploadedItems={uploadedItems}
                                  key={file?.name}
                                />
                              ))}
                            </List>
                          </Box>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "1em",
                          }}
                        >
                          <Button
                            disabled={isUploading}
                            variant="contained"
                            startIcon={
                              isUploading ? (
                                <HourglassTopIcon />
                              ) : (
                                <CloudUploadIcon />
                              )
                            }
                            onClick={() => {
                              addSimulation?.();
                            }}
                          >
                            Upload
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div id="folder-select-input">
                        {/* <input
                        type="file"
                        id="filepicker"
                        name="fileList"
                        onChange={(event) => {
                          let output = document.getElementById("listing");
                          for (const file of event.target.files) {
                            let item = document.createElement("li");
                            item.textContent = file.webkitRelativePath;
                            output.appendChild(item);
                          }
                        }}
                        webkitdirectory
                        multiple
                      /> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            top: 0,
                            left: 0,
                            height: "300px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "2px dashed",
                            borderColor: grey[500],
                            background: grey[200],
                            // ...styles,
                          }}
                        >
                          <FormLabel
                            htmlFor="multi-file-upload"
                            sx={{
                              textAlign: "center",
                              fontSize: "1.2em",
                              padding: 1,
                              minWidth: "300px",
                              width: "auto",
                              borderRadius: 2,
                              border: "1px solid white",
                              cursor: "pointer",
                              color: "#fff",
                              background: theme.palette.primary[700],
                              boxShadow: 10,
                            }}
                          >
                            Select a folder to upload
                          </FormLabel>
                        </Box>
                        <Box sx={{ display: "none" }}>
                          <input
                            multiple
                            webkitdirectory=""
                            type="file"
                            id="multi-file-upload"
                            ref={filePickerInput}
                            onChange={(event) => {
                              // let output = document.getElementById("listing");
                              let selected = [];
                              for (const file of event.target.files) {
                                console.log(file);
                                selected.push(file);
                              }
                              setSelectedFilesList(selected);
                            }}
                            // accept={fileType?.accept || FILE_TYPES.ALL.accept}
                          />
                        </Box>
                      </div>
                    )}
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        ></DialogActions>
      </Dialog>
    </>
  );
}

export default ClusterSimulationFileUploadDialog;
