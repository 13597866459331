import React, { useEffect, useMemo, useRef, useState } from "react";
import DateSearch from "./DateSearch";
import SearchSuggestionBox from "./SearchSuggestonBox";
import useDebounce from "../../../hooks/useDebounce";

const SearchInput = ({
  mode,
  activeItemIndex,
  searchOptions,
  values,
  data,
  inputRef,
  suggestionRef,
  isLoading,
  showSuggestions,
  setIsLoading,
  setData,
  setSearchOptions,
  setDatePickBox,
  setShowSuggestions,
  setSuggestionFilter,
  onSelectValueChanged = (option) => {},
  onSearchSubmit = (option) => {},
  onPartItemRemove = (option) => {},
  getSelectedOptions = (option) => {},
}) => {
  const placeholderText =
    mode === "key" ? "Search Key" : mode === "operator" ? "Operator" : "Value";
  const [query, setQuery] = useState("");
  const tempSelect = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const typeDelay = useDebounce(query, 500);
  const isDate =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op.isDate
      : values[values.length - 1]?.op.isDate);
  const isMultiSelect =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op.isMultiSelect
      : values[values.length - 1]?.op.isMultiSelect);
  const customSuggestion =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op.customSuggestion
      : values[values.length - 1]?.op.customSuggestion);
  const initialDates = {
    from: new Date(new Date().setFullYear("2023")),
    to: new Date(),
  };
  const [dateObj, setDateObj] = useState(
    JSON.parse(JSON.stringify(initialDates))
  );

  const filteredOptions = useMemo(() => {
    if (!customSuggestion) {
      return searchOptions.filter(({ value }) =>
        value?.toLowerCase()?.startsWith(query.toLowerCase())
      );
    }
    return searchOptions;
  }, [query, searchOptions, customSuggestion]);

  useEffect(() => {
    const handleFilterChangeRef = (data) => {
      if (mode === "value" || mode === "editValue") {
        const item =
          mode === "value"
            ? values[values.length - 1]
            : values[activeItemIndex];
        if (item["op"].customSuggestion) {
          setIsLoading(true);
          setSearchOptions([]);
          setData((prevValues) => ({
            ...prevValues,
            page: 1,
          }));
          setSuggestionFilter([
            {
              value: data,
              operatorValue: "startsWith",
              columnField: item.key.id,
              label: item.key.value,
              displayValue: data,
            },
          ]);
        }
      }
    };
    handleFilterChangeRef(typeDelay);
  }, [
    typeDelay,
    mode,
    values,
    activeItemIndex,
    setIsLoading,
    setSearchOptions,
    setData,
    setSuggestionFilter,
  ]);

  const enterKeyPress = (e) => {
    if (query.length > 0) {
      if (e.key === "Enter") {
        if (
          (mode === "value" && values[values.length - 1].op.customInput) ||
          (mode === "editValue" && values[activeItemIndex].op.customInput)
        ) {
          onSelectValueChanged([{ id: query, value: query }]);
          setQuery("");
        } else if (
          (mode === "value" &&
            !values[values.length - 1].op.customInput &&
            !values[values.length - 1].op.isMultiSelect) ||
          (mode === "editValue" &&
            !values[activeItemIndex].op.customInput &&
            !values[activeItemIndex].op.isMultiSelect)
        ) {
          const item =
            mode === "editValue"
              ? values[activeItemIndex]
              : values[values.length - 1];
          if (item["op"].results) {
            item["op"].results.some((res) => {
              if (query.toLowerCase() === res.value.toLowerCase()) {
                onSelectValueChanged([res]);
                setQuery("");
                return true;
              }
              return false;
            });
          }
        } else if (mode === "key") {
          searchOptions.some((key) => {
            if (key.value?.toLowerCase() === query?.toLowerCase()) {
              onSelectValueChanged([key]);
              setQuery("");
              return true;
            }
            return false;
          });
        } else if (mode === "operator") {
          values[values.length - 1].key.operators.some((op) => {
            if (op.value.toLowerCase() === query.toLowerCase()) {
              onSelectValueChanged([op]);
              setQuery("");
              return true;
            }
            return false;
          });
        }
      }
    } else if (e.key === "Backspace") {
      setShowSuggestions(true);
      onPartItemRemove();
    } else if (e.key === "Enter" && mode === "key") {
      setShowSuggestions(false);
      onSearchSubmit();
    }
  };

  const dateSearchProps = {
    onValueChanged: onSelectValueChanged,
    showSuggestions: showSuggestions,
    anchorEl: inputRef.current,
    dateObj: dateObj,
    suggestionRef: suggestionRef,
    setDateObj: setDateObj,
    setDatePickBox: setDatePickBox,
  };

  const searchSuggestionBoxProps = {
    tempSelect: tempSelect,
    mode: mode,
    data: data,
    options: filteredOptions,
    selOptions: getSelectedOptions(),
    searchQuery: query,
    isMultiSelect: isMultiSelect,
    onValueChanged: onSelectValueChanged,
    setQuery: setQuery,
    showSuggestions: showSuggestions,
    anchorEl: anchorEl,
    suggestionRef: suggestionRef,
    setData: setData,
    customSuggestion: customSuggestion,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
  };

  return (
    <>
      <input
        type="text"
        placeholder={placeholderText}
        onChange={(e) => {
          if (!isDate) {
            setQuery(e.target.value);
          }
          setShowSuggestions(true);
        }}
        onFocus={(e) => {
          setAnchorEl(e.target);
          setShowSuggestions(true);
        }}
        style={{
          flex: 1,
          border: "none",
          outline: "none",
          padding: "8px",
          minWidth: "100px",
        }}
        value={query}
        ref={inputRef}
        onKeyDown={(e) => enterKeyPress(e)}
      />
      {isDate ? (
        <DateSearch {...dateSearchProps} />
      ) : (
        <SearchSuggestionBox {...searchSuggestionBoxProps} />
      )}
    </>
  );
};

export default SearchInput;
