import React, { memo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import {
  addCommentForSimulation,
  updateCommentForSimulation,
} from "../../../services/cluster.js";
import CommentImageSelector from "./CommentImageSelector.jsx";
import uploadFileUsingS3Api from "../../../services/AwsFileUploader.js";
import { useQuery } from "react-query";
import {
  getServerConfiguration,
  getStsToken,
} from "../../../services/cluster.js";
import { useToast } from "../../toast/useToast.jsx";
import { GridCloseIcon } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateComment = ({ simulationUploadId, commentEdit = null }) => {
  const [commentValue, setCommentValue] = useState(
    commentEdit ? commentEdit?.comment : ""
  );
  const [priority, setPriority] = useState(
    commentEdit ? commentEdit?.priority : "low"
  );
  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentNames, setAttachmentNames] = useState([]);
  const [simulationsRepoBucket, setSimulationsRepoBucket] = useState(null);
  const [stsToken, setStsToken] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFileBeingUploaded, setCurrentFileBeingUploaded] = useState("");
  const [uploadedItems, setUploadedItems] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [allFilesUploadComplete, setAllFilesUploadComplete] = useState(false);

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  useQuery("getServerConfig", getServerConfiguration, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: serverConfig = {} } = data;
      setSimulationsRepoBucket(serverConfig.s3.SIMULATION_UPLOAD_BUCKET);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  useQuery("stsToken", getStsToken, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: token = {} } = data;
      setStsToken(token);
    },
    onError: (err) => {
      // setIsChangeLogError(true);
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  const updateAttachmentsList = (newItems) => {
    setAttachments(newItems);
    const names = newItems?.map((i) => {
      return i?.name;
    });
    setAttachmentNames(names);
  };

  const simulationUploadAction = async ({ file, data }) => {
    try {
      // setImageUploadActionLoading(true);
      if (!simulationsRepoBucket) {
        throw new Error("S3 bucket information not available.");
      }
      const result = await uploadFileUsingS3Api({
        bucket: simulationsRepoBucket,
        key: `${simulationUploadId}/${data?.simulationCommentId}/${file?.name}`,
        selectedFile: file,
        onInit: (instance) => {
          // setUploadInstance?.(instance || null);
        },
        onProgress: (progress) => {
          console.log("Progress data = ", progress);
          // setUploadStatus(UploadStatus.InProgress);
          if (progress?.part === 2) {
            // updateUploadStatus(
            //   data.simulationUploadId,
            //   UploadStatus.InProgress
            // );
          }
          // setUploadProgress({
          //   ...progress,
          //   uploadPct: progress.loaded / progress.total,
          // });
          const percentage = progress.loaded / progress.total;
          // console.log("Percentage  = ", percentage);
          // setUploadProgress(percentage);
        },
        creds: stsToken,
      });

      if (result) {
        // setUploadStatus(UploadStatus.Completed);
        // updateUploadStatus(data.simulationUploadId, UploadStatus.Completed);
      }
    } catch (error) {
      // const { message = IMAGE_UPLOAD_ERRORS.SOMETHING_WENT_WRONG } = error;
      // setErrorMsg(message);
      // setUploadStatus(UploadStatus.Failed);
    } finally {
      // setImageUploadActionLoading(false);
    }
  };

  const startUploadingFiles = async (data) => {
    setIsUploading(true);
    console.log(attachments);
    const itemsUploaded = [];
    for (const item of attachments) {
      // start of uploading
      setUploadProgress(0);
      setCurrentFileBeingUploaded(item?.name);
      console.log(item);

      // start file upload
      const contents = await simulationUploadAction({ file: item?.file, data });
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // After upload is done for this file
      // const uploadedFiles = JSON.parse(JSON.stringify(uploadedItems));
      // uploadedFiles[file?.name] = true;
      itemsUploaded.push(item?.name);
      // const newItems = [...uploadedItems, file?.name];
      console.log("New uploaded Items", itemsUploaded);
      setUploadedItems(itemsUploaded);
    }
    setCurrentFileBeingUploaded("");
    setAllFilesUploadComplete(true);
    // resetUploadForm();
    // handleClose();
  };

  const queryClient = useQueryClient();

  const { mutate: updateComment } = useMutation(updateCommentForSimulation, {
    onSuccess: async ({ data }) => {
      startUploadingFiles(data);
    },
    onError: (err) => {},
    onSettled: () => {
      queryClient.invalidateQueries("fetchCommentsForSimulation");
    },
  });

  const { mutate: addComment } = useMutation(addCommentForSimulation, {
    onSuccess: async ({ data }) => {
      startUploadingFiles(data);
    },
    onError: (err) => {},
    onSettled: () => {
      queryClient.invalidateQueries("fetchCommentsForSimulation");
    },
  });

  const update = () => {
    setIsUploading(true);
    const payload = {
      simulationUploadId: simulationUploadId,
      simulationCommentId: commentEdit?.simulationCommentId,
      comment: commentValue,
      priority: priority,
      attachments: attachmentNames,
    };
    updateComment(payload);
  };

  const add = () => {
    setIsUploading(true);
    const payload = {
      simulationUploadId: simulationUploadId,
      comment: commentValue,
      priority: priority,
      attachments: attachmentNames,
    };
    addComment(payload);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const resetCreateForm = () => {
    setCommentValue("");
    setPriority("low");
    setAttachments([]);
    setAttachmentNames([]);
    setSimulationsRepoBucket(null);
    setStsToken(null);
    setUploadProgress(0);
    setCurrentFileBeingUploaded("");
    setUploadedItems([]);
    setIsUploading(false);
    setAllFilesUploadComplete(false);
  };

  const handleClose = () => {
    setIsUploading(false);
    resetCreateForm();
    setOpen(false);
  };

  const handleSave = () => {
    if (commentEdit) {
      update();
    } else {
      add();
    }
  };

  return (
    <React.Fragment>
      {commentEdit ? (
        <MenuItem
          onClick={() => {
            handleClickOpen();
          }}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
      ) : (
        <Button
          size="small"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            handleClickOpen();
          }}
        >
          {commentEdit ? "Update Comment" : "Add Comment"}
        </Button>
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Enter new comment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              style={{ marginTop: "1em" }}
              fullWidth
              label="Comment"
              id="comment"
              value={commentValue}
              onChange={(event) => {
                setCommentValue(event.target.value);
              }}
            />
          </Box>
          <FormControl style={{ width: "200px", marginTop: "1em" }}>
            <InputLabel id="priority">Priority</InputLabel>
            <Select
              labelId="priority"
              id="demo-simple-select"
              value={priority}
              label="Priority"
              onChange={handlePriorityChange}
            >
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="low">Low</MenuItem>
            </Select>
          </FormControl>

          <Box>
            <CommentImageSelector
              simulationUploadId={simulationUploadId}
              updateAttachmentsList={updateAttachmentsList}
              uploadedItems={uploadedItems}
              currentFileBeingUploaded={currentFileBeingUploaded}
              uploadProgress={uploadProgress}
              isUploading={isUploading}
              allFilesUploadComplete={allFilesUploadComplete}
              handleClose={handleClose}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isUploading}>
            Close
          </Button>
          <Button onClick={handleSave} disabled={isUploading}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default memo(CreateComment);
