export const SearchKeyOptions = [
  {
    id: "serialNo",
    value: "Serial Id",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customInput: false,
        customSuggestion: true,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "contains",
        value: "contains",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "startsWith",
        value: "starts with",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "endsWith",
        value: "ends with",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "isAnyOf",
        value: "is any of",
        isMultiSelect: true,
        customInput: false,
        customSuggestion: true,
        isDate: false,
        input: true,
        results: [],
      },
    ],
  },
  {
    id: "vehicleKey",
    value: "Vehicle Key",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customInput: false,
        customSuggestion: true,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "contains",
        value: "contains",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "startsWith",
        value: "starts with",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "endsWith",
        value: "ends with",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "isAnyOf",
        value: "is any of",
        isMultiSelect: true,
        customInput: false,
        customSuggestion: true,
        isDate: false,
        input: true,
        results: [],
      },
      {
        id: "is",
        value: "is",
        isMultiSelect: false,
        customInput: false,
        customSuggestion: false,
        isDate: false,
        input: false,
        results: [
          { id: "isEmpty", value: "empty" },
          { id: "isNotEmpty", value: "not empty" },
        ],
      },
    ],
  },
  {
    id: "active",
    value: "Status",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customInput: false,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [
          { id: "true", value: "active" },
          { id: "false", value: "inactive" },
        ],
      },
    ],
  },
  {
    id: "certAvailable",
    value: "Certificate",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customSuggestion: false,
        customInput: false,
        isDate: false,
        input: true,
        results: [
          { id: "true", value: "Yes" },
          { id: "false", value: "No" },
        ],
      },
    ],
  },
  {
    id: "vehicle.vehicleFeatures.active",
    value: "Navigation Features",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customSuggestion: false,
        customInput: false,
        isDate: false,
        input: true,
        results: [
          { id: "true", value: "enabled" },
          { id: "false", value: "disable" },
        ],
      },
    ],
  },
  {
    id: "vehicle.subscriptions.active",
    value: "Subscriptions",
    type: "searchKey",
    operators: [
      {
        id: "equals",
        value: "=",
        isMultiSelect: false,
        customSuggestion: false,
        customInput: false,
        isDate: false,
        input: true,
        results: [
          { id: "true", value: "active" },
          { id: "false", value: "inactive" },
        ],
      },
    ],
  },
  {
    id: "vehicle.vehicleGroups.vehicleGroupId",
    value: "Vehicle Groups",
    type: "searchKey",
    operators: [
      {
        id: "in",
        value: "in",
        isMultiSelect: true,
        customInput: false,
        customSuggestion: false,
        isDate: false,
        input: true,
        results: [],
      },
    ],
  },
  {
    id: "serialNoCreatedAt",
    value: "HU Manufactured",
    type: "searchKey",
    operators: [
      {
        id: "between",
        value: "between",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: true,
        input: true,
        results: [],
      },
    ],
  },
  {
    id: "vehicleKeyUpdatedAt",
    value: "Vehicle Manufactured",
    type: "searchKey",
    operators: [
      {
        id: "between",
        value: "between",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: true,
        input: true,
        results: [],
      },
    ],
  },
  {
    id: "vehicle.vehiclePurchaseDetails.purchaseDate",
    value: "Purchased",
    type: "searchKey",
    operators: [
      {
        id: "between",
        value: "between",
        isMultiSelect: false,
        customInput: true,
        customSuggestion: false,
        isDate: true,
        input: true,
        results: [],
      },
    ],
  },
];
