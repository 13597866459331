import React, { useRef, useState } from "react";
import { useParams, Link as RouterLink, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Paper, Fab, Drawer } from "@mui/material";
import { getCurrentPathTab } from "../services/Utils";
import { getClusterImageById } from "../services/cluster";
import SimulationPreview from "../components/clusterSimulations/SimulationPreview";

const PATHS = ["preview"];

export default function Cluster() {
  let params = useParams();
  const { pathname } = useLocation();
  const [value, setValue] = useState(
    getCurrentPathTab(pathname, PATHS, PATHS[0])
  );
  const [simulationDetails, setSimulationDetails] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: { data } = { data: [] } } = useQuery(
    ["fetchSimulationDetails", params?.simulationId],
    getClusterImageById,
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.simulationId,
      onSuccess: (res) => {
        console.log("cluster res ", res);
        setSimulationDetails(res);
      },
    }
  );

  return (
    <div className="fragmentContainer">
      {/* <BreadCrumbsRendered pathname={pathname} /> */}
      <Box
        sx={{ width: "100%", marginTop: 0, padding: "8px" }}
        component={Paper}
      >
        <SimulationPreview {...params} simulationDetails={simulationDetails} />
      </Box>
    </div>
  );
}
