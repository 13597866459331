import React, { memo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreateComment from "./CreateComment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteCommentForSimulation,
  getCommentsForSimulation,
} from "../../../services/cluster";
import DeleteCommentConfirm from "./DeleteCommentConfirm";
import { DateAndTimeGridCell } from "../../common/DateAndTimeGridCell";
import PreviewIcon from "@mui/icons-material/Preview";
import CommentDetails from "./CommentDetails";
import Draggable from "react-draggable";
import CommentIcon from "@mui/icons-material/Comment";
import CloseIcon from "@mui/icons-material/Close";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ width: "90%", maxWidth: "90%" }} {...props} />
    </Draggable>
  );
}

const CommentsList = ({
  toggleCommentsTable,
  showCommentsTable,
  simulationUploadId,
  toggleCommentsDrawer,
}) => {
  const queryClient = useQueryClient();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [deleteConfirmDialogProps, setShowDeleteConfirmDialogProps] =
    useState(null);
  const [showCommentDetails, setShowCommentDetails] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeCommentDetails = () => {
    setShowCommentDetails(null);
  };

  let queryName = "fetchCommentsForSimulation",
    page = 1,
    pageSize = 10,
    field = "",
    sort = "desc",
    filterValue,
    columns;

  const { data: { data } = { data: [] } } = useQuery(
    [
      queryName,
      page,
      pageSize,
      field,
      sort,
      filterValue,
      columns,
      simulationUploadId,
    ],
    getCommentsForSimulation,
    {
      enabled: !!simulationUploadId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const { data: { meta = {}, res = [] } = {} } = response;
        setRows(res);
      },
    }
  );

  const { mutate: deleteComment } = useMutation(deleteCommentForSimulation, {
    onSuccess: async ({ data }) => {},
    onError: (err) => {},
    onSettled: () => {
      setShowDeleteConfirmDialogProps(null);
      queryClient.invalidateQueries("fetchCommentsForSimulation");
    },
  });

  const cols = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 70,
      cellClassName: "actions",
      getActions: (d) => {
        const { id, row } = d;
        return [
          <GridActionsCellItem
            icon={<PreviewIcon color="primary" />}
            label="Details View"
            onClick={() => {
              setShowCommentDetails(row);
            }}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={() => {
          //     const show = true;
          //     const onAccept = () => {
          //       deleteComment(row?.simulationCommentId);
          //     };
          //     const onCancel = () => {
          //       setShowDeleteConfirmDialogProps(null);
          //     };

          //     const props = {
          //       onAccept,
          //       onCancel,
          //       show: true,
          //       comment: row?.comment,
          //     };
          //     setShowDeleteConfirmDialogProps(props);
          //   }}
          //   color="inherit"
          // />,
        ];
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 70,
      // editable: true,
      // type: "singleSelect",
      // valueOptions: ["High", "Medium", "Low"],
    },
    {
      field: "attachments",
      headerName: "Attachments",
      width: 100,
      renderCell: ({ row }) => {
        return row?.attachments.length;
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 1000,
      flex: 1,
      // editable: true,
    },
    // {
    //   field: "joinDate",
    //   headerName: "Join date",
    //   type: "date",
    //   //   width: 180,
    //   editable: true,
    // },
    {
      field: "uploadedBy",
      headerName: "Created By",
      // type: "number",
      //   width: 80,
      // align: "left",
      // headerAlign: "left",
      // editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      //   width: 180,
      // editable: true,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
  ];

  const createProps = {
    simulationUploadId,
  };

  return (
    <>
      <Fab
        onClick={handleClickOpen}
        variant="extended"
        size="medium"
        color="primary"
      >
        <CommentIcon sx={{ mr: 1 }} />
        Comments
      </Fab>

      <Dialog
        id="comments-list-dialog"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="draggable-handle"
          >
            <div>
              <Typography variant="h6">Comments</Typography>
            </div>
            <Tooltip title={showCommentsTable ? "Collapse" : "Expand"}>
              <IconButton
                color="primary"
                onClick={() => {
                  toggleCommentsTable();
                }}
              >
                {showCommentsTable ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Tooltip>
            <div className="dummy-element"></div>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            <div
              style={{
                height: showCommentsTable ? "calc(100vh - 229px)" : "250px",
              }}
            >
              <DataGrid
                style={{ height: "100%" }}
                density="compact"
                rows={rows}
                columns={cols}
                editMode="row"
                rowModesModel={rowModesModel}
                // onRowModesModelChange={handleRowModesModelChange}
                // onRowEditStop={handleRowEditStop}
                // processRowUpdate={processRowUpdate}
                isRowSelectable={false}
                rowSelection={false}
                // isRowEditable={(params) => {
                //   console.log("params = ", params);
                //   return params?.row?.isNew && params?.row?.inEditing;
                // }}
                getRowId={(row) => row?.simulationCommentId}
                // slots={{
                //   toolbar: <Typography>Test toolbar</Typography>,
                //   // <EditToolbar setAddComment={setAddComment} />,
                // }}
                // slotProps={{
                //   toolbar: { setRows, setRowModesModel },
                // }}
              />
            </div>
            {deleteConfirmDialogProps ? (
              <DeleteCommentConfirm {...deleteConfirmDialogProps} />
            ) : null}
            {showCommentDetails ? (
              <CommentDetails
                details={showCommentDetails}
                handleClose={closeCommentDetails}
                simulationUploadId={simulationUploadId}
              />
            ) : null}
          </>
        </DialogContent>
        <DialogActions>
          <CreateComment {...createProps} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(CommentsList);
