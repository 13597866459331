import React, { useRef, useState, memo } from "react";
import { Box, Fab, Drawer, Typography } from "@mui/material";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
import styled from "styled-components";
import { useTheme } from "@mui/material/styles";
import SimulationOverview from "./SimulationOverview";
import SimulationViewer from "./SimulationViewer";
import CommentsList from "./comments/CommentsList";
import { getColumnsData } from "../release/ReleaseColumns";

const SimulationPreview = ({ simulationDetails }) => {
  const theme = useTheme();
  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [showCommentsTable, setShowCommentsTable] = useState(false);
  const [addComment, setAddComment] = useState(null);
  // const { imageId } = props;

  const StyledDrawer = styled(Drawer)`
    &.MuiDrawer-root > div {
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: ${theme.palette.primary[700]};
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
    }
    &:hover.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: red;
    }
    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: purple;
    }
  `;

  const columns = getColumnsData({ hideActions: false });
  const clusterViewerRef = useRef(null);
  const enterFullscreen = () => {
    const elem = clusterViewerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  const toggleDetailsDrawer = () => {
    setCommentsDrawerOpen(false);
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  const toggleCommentsDrawer = () => {
    setDetailsDrawerOpen(false);
    setCommentsDrawerOpen(!commentsDrawerOpen);
  };
  const toggleCommentsTable = () => {
    setShowCommentsTable(!showCommentsTable);
  };

  return (
    <>
      <Box style={{ display: "flex" }}>
        <Typography variant="h4" style={{ marginLeft: "25px" }}>
          {simulationDetails?.simulationName}
        </Typography>
        <Typography variant="caption">{simulationDetails?.version}</Typography>
      </Box>
      <Box
        style={{ padding: "0" }}
        sx={{ height: "calc(100vh - 96px)", width: "100%" }}
        ref={clusterViewerRef}
      >
        <div
          id="cluster-preview-controls"
          style={{
            display: "flex",
            position: "absolute",
            right: "26px",
            margin: "8px",
            width: "275px",
            justifyContent: "space-between",
          }}
        >
          <CommentsList
            showCommentsTable={showCommentsTable}
            toggleCommentsTable={toggleCommentsTable}
            simulationUploadId={simulationDetails?.simulationUploadId}
            toggleCommentsDrawer={toggleCommentsDrawer}
          />

          <SimulationOverview
            toggleDetailsDrawer={toggleDetailsDrawer}
            simulationDetails={simulationDetails}
          />

          {/* <Fab
            onClick={() => {
              handleFullscreen();
            }}
            variant="extended"
            size="medium"
            color="secondary"
          >
            <FullscreenIcon />
          </Fab> */}
        </div>

        <SimulationViewer
          id="cluster-viewer"
          src={
            // TODO: include the croudfront url instead of the s3 object url
            simulationDetails?.downloadUrl
          }
        />
      </Box>
    </>
  );
};

export default memo(SimulationPreview);
