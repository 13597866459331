import React from "react";
import { Box, Chip } from "@mui/material";
import { ValueChicklet } from "./ValueChicklet";
import CloseIcon from "@mui/icons-material/Close";
import { formatDisplayDatesForSearch } from "../../../services/Utils";

export function SearchItems({
  index,
  searchKey,
  operator,
  searchVal,
  onItemRemoved,
  onItemEdited,
  mode,
}) {
  if (searchVal) {
    if (operator.value === "between") {
      searchVal = searchVal.value;
      searchVal = formatDisplayDatesForSearch(searchVal);
    } else if (searchVal.length > 0) {
      searchVal = searchVal.map((o) => o.value).join(",");
    } else {
      searchVal = searchVal.value;
    }
  }
  return (
    <div
      className={
        searchVal && operator.value ? "searchItem" : "searchIncomplete"
      }
      style={{ height: "32px" }}
    >
      <div className="key" style={{ fontSize: "small", height: "100%" }}>
        {searchKey}
      </div>

      <div className="operator" style={{ fontSize: "small", height: "100%" }}>
        {operator.value}
      </div>

      <ValueChicklet
        index={index}
        mode={mode}
        text={searchVal}
        onEditClicked={onItemEdited}
      />
      {mode !== "editValue" && (
        <CloseIcon
          className="remove-search-item-icon"
          onClick={(event) => {
            event.stopPropagation();
            onItemRemoved(index);
          }}
          style={{
            height: "16px",
            width: "16px",
            display: "block",
            backgroundColor: "inherit",
          }}
        />
      )}
    </div>
  );
}
